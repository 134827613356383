<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col>
          <v-card class="card-shadow card-padding border-radius-xl">
            <!--v-card-title class="
                text-h6
                font-weight-bolder
                text-typo
                align-end
                ">
                신규예약등록
            </v-card-title-->
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >신규예약등록</v-card-title
            >
            <v-card-text>
              <div>
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >예약일자</label
                >
                <div @click="changeDateDialog = true" style="cursor: pointer">
                  <v-text-field
                    disabled
                    v-model="item.reservationDate"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    class="
                      font-size-input
                      placeholder-lighter
                      text-light-input
                      border border-radius-md
                      mt-1
                    "
                    style="cursor: pointer"
                  >
                  </v-text-field>
                </div>
              </div>
              <div class="mt-3">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >예약시간</label
                >
                <div @click="showChangeTimeDialog" style="cursor: pointer">
                  <v-text-field
                    disabled
                    v-model="item.reservationTime"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    class="
                      font-size-input
                      placeholder-lighter
                      text-light-input
                      border border-radius-md
                      mt-1
                    "
                    style="cursor: pointer"
                  >
                  </v-text-field>
                </div>
              </div>
              <div class="mt-3">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >고객선택</label
                >
                <v-select
                  hide-details
                  :items="appUsers"
                  color="rgba(0,0,0,.6)"
                  class="input-style mt-1"
                  outlined
                  dense
                  v-model="selectedAppUser"
                >
                  <template v-slot:item="{ item }">
                    <div class="text-sm text-body">
                      {{ item.name }} ({{ item.gender }}, {{ getAge(item)
                      }}{{ item.phone.slice(-4) }})
                    </div>
                  </template>
                  <template v-slot:selection="{ item }">
                    <div class="text-sm text-body">
                      {{ item.name }} ({{ item.gender }}, {{ getAge(item)
                      }}{{ item.phone.slice(-4) }})
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="mt-3">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >담당의료진/프로그램 선택</label
                >
                <v-select
                  hide-details
                  :items="doctors"
                  color="rgba(0,0,0,.6)"
                  class="input-style mt-1"
                  outlined
                  dense
                  v-model="item.reservationDoctor"
                  @change="loadClinicTypes"
                >
                  <template v-slot:item="{ item }">
                    <div class="text-sm text-body">{{ item.name }}</div>
                  </template>
                  <template v-slot:selection="{ item }">
                    <div class="text-sm">{{ item.name }}</div>
                  </template>
                </v-select>
              </div>
              <div class="mt-3">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >진료형태</label
                >
                <v-select
                  hide-details
                  :items="['대면진료', '음성진료', '화상진료']"
                  color="rgba(0,0,0,.6)"
                  class="input-style mt-1"
                  outlined
                  dense
                  v-model="item.reservationClinicType"
                >
                </v-select>
              </div>
              <div class="mt-3">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >진료요청내용</label
                >
                <v-text-field
                  v-model="item.symptomDescription"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  light
                  class="
                    font-size-input
                    placeholder-lighter
                    text-light-input
                    border border-radius-md
                    mt-1
                  "
                >
                </v-text-field>
              </div>
              <div class="d-flex flex-column h-100 mt-4 mb-2">
                <div class="d-flex justify-space-between align-center">
                  <h6 class="mb-0 text-typo text-h6 font-weight-bold">
                    비급여 요청 항목
                  </h6>
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    style="height: 43px; font-size: 14px"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                    "
                    @click="showAddNonBenefitDialog"
                  >
                    <v-icon class="me-2">fas fa-plus</v-icon>
                    추가
                  </v-btn>
                </div>
                <div class="pa-4 bg-gray-100 border-radius-lg mt-4">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                      제증명서
                    </h6>
                    <span class="mb-2 text-xs text-body">
                      {{ certificates }}
                    </span>
                    <div v-if="certificateEtc.length > 0">
                      <div v-for="(etc, i) in certificateEtc" :key="i">
                        <div class="text-sm text-typo font-weight-bold">
                          {{ etc.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column" style="margin-top: 10px">
                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                      비급여 처방/시술
                    </h6>
                    <span class="mb-2 text-xs text-body">
                      {{ nonBenefitMedicines }}
                    </span>
                  </div>
                  <div class="d-flex flex-column" style="margin-top: 10px">
                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                      제품/서비스/기타
                    </h6>
                    <span class="mb-2 text-xs text-body">
                      {{ etc }}
                    </span>
                  </div>
                  <div class="d-flex flex-column" style="margin-top: 10px">
                    <h6 class="mb-3 text-sm text-typo font-weight-bold">
                      건강검진/검사
                    </h6>
                    <span class="mb-2 text-xs text-body">
                      {{ checkups }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >결제방법</label
                >
                <v-select
                  hide-details
                  :items="getPayTypes()"
                  color="rgba(0,0,0,.6)"
                  class="input-style mt-1"
                  outlined
                  dense
                  v-model="item.reservationPayType"
                >
                </v-select>
              </div>
            </v-card-text>
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="saveReservation"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                "
                >저장
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="changeDateDialog" max-width="400px">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="d-flex justify-center align-center pt-4">
          <v-date-picker
            :event-color="'#fd7e14'"
            :show-current="false"
            locale="ko"
            no-title
            v-model="item.reservationDate"
            color="#69CBBC"
            @change="onChangeReservationDate"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeTimeDialog" max-width="700px">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="d-flex justify-center align-center pt-4">
          <v-simple-table class="w-100">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">시간</th>
                  <th class="text-center">예약 가능 고객 수</th>
                  <th class="text-center">예약고객명</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in reservationTimes"
                  :key="i"
                  :style="
                    isAvailableReservation(item.availableCount)
                      ? 'cursor:pointer'
                      : 'background-color:#e9ecef'
                  "
                  @click="onClickReservationTime(item)"
                >
                  <td class="text-center">
                    {{ $moment(item.time).format("HH시 mm분") }}
                  </td>
                  <td class="text-center">{{ item.availableCount }}</td>
                  <td class="text-center">{{ item.reservationPatients }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center mt-2">
          <v-btn
            @click="changeTimeDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addNonBenefitDialog" max-width="600px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >비급여 항목 추가</span
          >
        </div>
        <v-card-text class="py-2">
          <div
            class="d-flex justify-space-between align-center py-2"
            style="cursor: pointer"
            @click="addNonBenefitDrop1 = !addNonBenefitDrop1"
          >
            <div class="text-md font-weight-bold text-typo">제증명서</div>
            <v-icon>{{
              addNonBenefitDrop1 ? "fas fa-chevron-up" : "fas fa-chevron-down"
            }}</v-icon>
          </div>
          <div v-if="addNonBenefitDrop1">
            <div v-for="(certificate, i) in addNonBenefitCertificates" :key="i">
              <v-checkbox
                v-model="nonBenefitCheckMap[certificate.id]"
                color="#69CBBC"
                :ripple="false"
                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  {{ certificate.name }} ({{
                    $utils.separateThousands(certificate.price)
                  }}원)
                </template>
              </v-checkbox>

              <div :key="certificate.key">
                <div
                  v-if="
                    $utils.isEmpty(certificate.selections) === false &&
                    nonBenefitCheckMap[certificate.id]
                  "
                  class="mt-2"
                >
                  <div
                    v-for="(selection, j) in certificate.selections"
                    :key="j"
                  >
                    <div class="text-typo text-md font-weight-bold my-1 ms-2">
                      - 선택 옵션{{ j + 1
                      }}{{
                        selection.requiredCount > 0
                          ? " (필수 선택 " + selection.requiredCount + ")"
                          : ""
                      }}
                    </div>
                    <div v-for="(option, k) in selection.options" :key="k">
                      <div class="d-flex justify-start align-center ms-4">
                        <v-checkbox
                          v-model="option.checked"
                          color="#69CBBC"
                          :ripple="false"
                          class="
                            mx-0
                            mb-1
                            mt-0
                            checkbox-custom checkbox-thinner
                          "
                          hide-details
                        >
                          <template v-slot:label>
                            {{ option.name }} ({{
                              $utils.separateThousands(option.price)
                            }}원)
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--div v-if="$utils.isEmpty(certificate.selections)==false">
                  <div v-for="selection, j in certificate.selections" :key="j">
                      <div class="d-flex justify-start align-center">
                          <v-checkbox
                              v-model="selection.checked"
                              color="#69CBBC"
                              :ripple="false"
                              class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                              hide-details>
                              <template v-slot:label>
                                  {{ selection.name }} ({{ $utils.separateThousands(selection.price) }}원)
                              </template>
                          </v-checkbox>
                      </div>
                  </div>
              </div-->
            </div>
            <div class="d-flex justify-start align-center">
              <v-checkbox
                v-model="addNonBenefitCertificateUserReq.checked"
                color="#69CBBC"
                :ripple="false"
                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                hide-details
              >
              </v-checkbox>
              <v-text-field
                v-model="addNonBenefitCertificateUserReq.name"
                hide-details
                outlined
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-lighter
                "
                placeholder="기타"
              >
              </v-text-field>
            </div>
          </div>
          <div
            class="d-flex justify-space-between align-center py-2"
            style="cursor: pointer"
            @click="addNonBenefitDrop2 = !addNonBenefitDrop2"
          >
            <div class="text-md font-weight-bold text-typo">
              비급여 처방/시술
            </div>
            <v-icon>{{
              addNonBenefitDrop2 ? "fas fa-chevron-up" : "fas fa-chevron-down"
            }}</v-icon>
          </div>
          <div v-if="addNonBenefitDrop2">
            <div v-for="(medicine, i) in addNonBenefitMedicines" :key="i">
              <v-checkbox
                v-model="nonBenefitCheckMap[medicine.id]"
                color="#69CBBC"
                :ripple="false"
                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  {{ medicine.name }} ({{
                    $utils.separateThousands(medicine.price)
                  }}원)
                </template>
              </v-checkbox>

              <div :key="medicine.key">
                <div
                  v-if="
                    $utils.isEmpty(medicine.selections) === false &&
                    nonBenefitCheckMap[medicine.id]
                  "
                  class="mt-2"
                >
                  <div v-for="(selection, j) in medicine.selections" :key="j">
                    <div class="text-typo text-md font-weight-bold my-1 ms-2">
                      - 선택 옵션{{ j + 1
                      }}{{
                        selection.requiredCount > 0
                          ? " (필수 선택 " + selection.requiredCount + ")"
                          : ""
                      }}
                    </div>
                    <div v-for="(option, k) in selection.options" :key="k">
                      <div class="d-flex justify-start align-center ms-4">
                        <v-checkbox
                          v-model="option.checked"
                          color="#69CBBC"
                          :ripple="false"
                          class="
                            mx-0
                            mb-1
                            mt-0
                            checkbox-custom checkbox-thinner
                          "
                          hide-details
                        >
                          <template v-slot:label>
                            {{ option.name }} ({{
                              $utils.separateThousands(option.price)
                            }}원)
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-space-between align-center py-2"
            style="cursor: pointer"
            @click="addNonBenefitDrop3 = !addNonBenefitDrop3"
          >
            <div class="text-md font-weight-bold text-typo">
              제품/서비스/기타
            </div>
            <v-icon>{{
              addNonBenefitDrop3 ? "fas fa-chevron-up" : "fas fa-chevron-down"
            }}</v-icon>
          </div>
          <div v-if="addNonBenefitDrop3">
            <div v-for="(etc, i) in addNonBenefitEtc" :key="i">
              <v-checkbox
                v-model="nonBenefitCheckMap[etc.id]"
                color="#69CBBC"
                :ripple="false"
                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  {{ etc.name }} ({{ $utils.separateThousands(etc.price) }}원)
                </template>
              </v-checkbox>

              <div :key="etc.key">
                <div
                  v-if="
                    $utils.isEmpty(etc.selections) === false &&
                    nonBenefitCheckMap[etc.id]
                  "
                  class="mt-2"
                >
                  <div v-for="(selection, j) in etc.selections" :key="j">
                    <div class="text-typo text-md font-weight-bold my-1 ms-2">
                      - 선택 옵션{{ j + 1
                      }}{{
                        selection.requiredCount > 0
                          ? " (필수 선택 " + selection.requiredCount + ")"
                          : ""
                      }}
                    </div>
                    <div v-for="(option, k) in selection.options" :key="k">
                      <div class="d-flex justify-start align-center ms-4">
                        <v-checkbox
                          v-model="option.checked"
                          color="#69CBBC"
                          :ripple="false"
                          class="
                            mx-0
                            mb-1
                            mt-0
                            checkbox-custom checkbox-thinner
                          "
                          hide-details
                        >
                          <template v-slot:label>
                            {{ option.name }} ({{
                              $utils.separateThousands(option.price)
                            }}원)
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-space-between align-center py-2"
            style="cursor: pointer"
            @click="addNonBenefitDrop4 = !addNonBenefitDrop4"
          >
            <div class="text-md font-weight-bold text-typo">건강검진/검사</div>
            <v-icon>{{
              addNonBenefitDrop4 ? "fas fa-chevron-up" : "fas fa-chevron-down"
            }}</v-icon>
          </div>
          <div v-if="addNonBenefitDrop4">
            <div v-for="(checkup, i) in addNonBenefitCheckups" :key="i">
              <v-checkbox
                v-model="nonBenefitCheckMap[checkup.id]"
                color="#69CBBC"
                :ripple="false"
                class="mx-0 mb-1 mt-0 checkbox-custom checkbox-thinner"
                hide-details
                @change="checkup.key++"
              >
                <template v-slot:label>
                  {{ checkup.name }} ({{
                    $utils.separateThousands(checkup.price)
                  }}원)
                </template>
              </v-checkbox>

              <div :key="checkup.key">
                <div
                  v-if="
                    $utils.isEmpty(checkup.selections) === false &&
                    nonBenefitCheckMap[checkup.id]
                  "
                  class="mt-2"
                >
                  <div v-for="(selection, j) in checkup.selections" :key="j">
                    <div class="text-typo text-md font-weight-bold my-1 ms-2">
                      - 선택 옵션{{ j + 1
                      }}{{
                        selection.requiredCount > 0
                          ? " (필수 선택 " + selection.requiredCount + ")"
                          : ""
                      }}
                    </div>
                    <div v-for="(option, k) in selection.options" :key="k">
                      <div class="d-flex justify-start align-center ms-4">
                        <v-checkbox
                          v-model="option.checked"
                          color="#69CBBC"
                          :ripple="false"
                          class="
                            mx-0
                            mb-1
                            mt-0
                            checkbox-custom checkbox-thinner
                          "
                          hide-details
                        >
                          <template v-slot:label>
                            {{ option.name }} ({{
                              $utils.separateThousands(option.price)
                            }}원)
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="addNonBenefitItems"
            dark
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-3
              px-6
              text-capitalize
            "
            color="primary"
            >추가하기
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-secondary
              pa-2
              text-capitalize
            "
            color="#5e72e4"
            @click="addNonBenefitDialog = false"
            >닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      centered
      v-model="snackbar"
      :color="snackbarColor"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55</v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">{{
            snackbarTitle
          }}</span>
          <br />
          {{ snackbarMessage }}
        </p>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="workingStateDialog" width="300">
      <v-card>
        <v-card-title class="d-flex justify-center">
          <span class="text-body text-sm mb-1">
            처리중입니다. 잠시만 기다려주세요.
          </span>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NewReservation",
  props: {
    prop: {
      default: null,
    },
  },
  data: () => ({
    changeDateDialog: false,
    changeTimeDialog: false,
    item: {},
    reservationTimes: [],
    hospitalWorkerId: "",
    hospitalWorker: {},
    hospital: {},
    doctors: [],
    appUsers: [],
    selectedAppUser: {},
    addNonBenefitDialog: false,
    addNonBenefitDrop1: false,
    addNonBenefitDrop2: false,
    addNonBenefitDrop3: false,
    addNonBenefitDrop4: false,
    addNonBenefitCertificates: [],
    addNonBenefitCertificateEtc: [],
    addNonBenefitMedicines: [],
    addNonBenefitEtc: [],
    addNonBenefitCheckups: [],
    addNonBenefitCertificateUserReq: {},
    nonBenefitCheckMap: {},
    certificates: "",
    nonBenefitMedicines: "",
    etc: "",
    checkups: "",
    certificateEtc: [],
    workingStateDialog: false,
    snackbar: false,
    snackbarColor: "#ea0606",
    snackbarTitle: "알림",
    snackbarMessage: "",
  }),
  watch: {
    async item() {},
  },
  mounted: async function () {
    await this.init();
  },
  methods: {
    getPayTypes() {
      let payTypes = [];
      if (this.$utils.isEmpty(this.hospital.enableAutoPay) === false) {
        if (this.hospital.enableAutoPay) {
          payTypes = ["자동결제", "직접결제"];
        } else {
          payTypes = ["직접결제"];
        }
      } else {
        payTypes = ["자동결제", "직접결제"];
      }
      return payTypes;
    },
    onChangeReservationDate() {
      if (this.$utils.isEmpty(this.item.reservationDate) === false) {
        let reservationDateTime = new Date(
          this.item.reservationDate + "T00:00:00"
        );
        if (reservationDateTime.getDay() === 0) {
          // sunday
          if (this.hospital.sundayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        } else if (reservationDateTime.getDay() === 1) {
          if (this.hospital.mondayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        } else if (reservationDateTime.getDay() === 2) {
          if (this.hospital.tuesdayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        } else if (reservationDateTime.getDay() === 3) {
          if (this.hospital.wednesdayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        } else if (reservationDateTime.getDay() === 4) {
          if (this.hospital.thursdayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        } else if (reservationDateTime.getDay() === 5) {
          if (this.hospital.fridayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        } else if (reservationDateTime.getDay() === 6) {
          if (this.hospital.saturdayEnable === false) {
            this.item.reservationDate = null;
            this.showWarningSnackbar(
              "병원 휴무일입니다. 다른 날짜를 선택해주세요."
            );
          }
        }
      }

      if (this.$utils.isEmpty(this.item.reservationDate) === false) {
        this.loadAvailableDoctors();
        this.changeDateDialog = false;
      }
    },
    loadClinicTypes() {
      this.item.reservationClinicType = null;
      let clinicTypes = [];
      if (this.$utils.isEmpty(this.item.reservationDoctor) === false) {
        if (this.item.reservationDoctor.availableNormalClinic) {
          clinicTypes.push("대면진료");
        }
        if (this.item.reservationDoctor.availableOnlineClinic) {
          if (this.item.reservationDoctor.availableAudioCall) {
            clinicTypes.push("음성진료");
          }
          if (this.item.reservationDoctor.availableVideoCall) {
            clinicTypes.push("화상진료");
          }
        }
      }
      this.clinicTypes = clinicTypes;
    },
    compareTimeOnly(date1, date2) {
      // -1이면 date1이 이전시간
      const time1 =
        date1.getHours() * 60 * 60 +
        date1.getMinutes() * 60 +
        date1.getSeconds();
      const time2 =
        date2.getHours() * 60 * 60 +
        date2.getMinutes() * 60 +
        date2.getSeconds();

      if (time1 < time2) {
        return -1; // 이전 시간
      } else if (time1 > time2) {
        return 1; // 이후 시간
      } else {
        return 0;
      }
    },
    async loadAvailableDoctors() {
      this.item.reservationDoctor = null;
      if (this.$utils.isEmpty(this.item.reservationDate) === false) {
        if (this.$utils.isEmpty(this.item.reservationTime) === false) {
          try {
            //console.log('doctors', this.doctors);
            //console.log('this.item.reservationDate', this.item.reservationDate);
            //console.log('this.item.reservationTime', this.item.reservationTime);

            let timeText = this.item.reservationTime.replace("시 ", ":");
            timeText = timeText.replace("분", ":00");
            let reservationDateTime = new Date(
              this.item.reservationDate + "T" + timeText
            );

            let doctors = await this.$database.loadHospitalWorkersByTypeIn(
              this.hospital.id,
              ["HospitalWorkerType.doctor", "HospitalWorkerType.service"]
            );
            let availableDoctors = [];
            for (let i = 0; i < doctors.length; i++) {
              if (doctors[i].enable !== true) {
                continue;
              }

              if (reservationDateTime.getDay() === 0) {
                // sunday
                if (doctors[i].sundayEnable) {
                  let openTime = doctors[i].sundayOpenAt.toDate();
                  let closeTime = doctors[i].sundayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(doctors[i].sundayLunchStartAt) ===
                        false
                      ) {
                        let lunchStartTime =
                          doctors[i].sundayLunchStartAt.toDate();
                        let lunchEndTime = doctors[i].sundayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              } else if (reservationDateTime.getDay() === 1) {
                // monday
                if (doctors[i].mondayEnable) {
                  let openTime = doctors[i].mondayOpenAt.toDate();
                  let closeTime = doctors[i].mondayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(doctors[i].mondayLunchStartAt) ===
                        false
                      ) {
                        let lunchStartTime =
                          doctors[i].mondayLunchStartAt.toDate();
                        let lunchEndTime = doctors[i].mondayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              } else if (reservationDateTime.getDay() === 2) {
                // tuesday
                if (doctors[i].tuesdayEnable) {
                  let openTime = doctors[i].tuesdayOpenAt.toDate();
                  let closeTime = doctors[i].tuesdayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(doctors[i].tuesdayLunchStartAt) ===
                        false
                      ) {
                        let lunchStartTime =
                          doctors[i].tuesdayLunchStartAt.toDate();
                        let lunchEndTime =
                          doctors[i].tuesdayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              } else if (reservationDateTime.getDay() === 3) {
                // wednesday
                if (doctors[i].wednesdayEnable) {
                  let openTime = doctors[i].wednesdayOpenAt.toDate();
                  let closeTime = doctors[i].wednesdayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(
                          doctors[i].wednesdayLunchStartAt
                        ) === false
                      ) {
                        let lunchStartTime =
                          doctors[i].wednesdayLunchStartAt.toDate();
                        let lunchEndTime =
                          doctors[i].wednesdayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              } else if (reservationDateTime.getDay() === 4) {
                // thursday
                if (doctors[i].thursdayEnable) {
                  let openTime = doctors[i].thursdayOpenAt.toDate();
                  let closeTime = doctors[i].thursdayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(doctors[i].thursdayLunchStartAt) ===
                        false
                      ) {
                        let lunchStartTime =
                          doctors[i].thursdayLunchStartAt.toDate();
                        let lunchEndTime =
                          doctors[i].thursdayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              } else if (reservationDateTime.getDay() === 5) {
                // friday
                if (doctors[i].fridayEnable) {
                  let openTime = doctors[i].fridayOpenAt.toDate();
                  let closeTime = doctors[i].fridayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(doctors[i].fridayLunchStartAt) ===
                        false
                      ) {
                        let lunchStartTime =
                          doctors[i].fridayLunchStartAt.toDate();
                        let lunchEndTime = doctors[i].fridayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              } else if (reservationDateTime.getDay() === 6) {
                // saturday
                if (doctors[i].saturdayEnable) {
                  let openTime = doctors[i].saturdayOpenAt.toDate();
                  let closeTime = doctors[i].saturdayCloseAt.toDate();
                  if (
                    this.compareTimeOnly(reservationDateTime, openTime) >= 0
                  ) {
                    if (
                      this.compareTimeOnly(reservationDateTime, closeTime) <= 0
                    ) {
                      let inLunchTime = false;
                      // 점심시간이 있을 경우
                      if (
                        this.$utils.isEmpty(doctors[i].saturdayLunchStartAt) ===
                        false
                      ) {
                        let lunchStartTime =
                          doctors[i].saturdayLunchStartAt.toDate();
                        let lunchEndTime =
                          doctors[i].saturdayLunchEndAt.toDate();
                        if (
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchStartTime
                          ) > 0 &&
                          this.compareTimeOnly(
                            reservationDateTime,
                            lunchEndTime
                          ) < 0
                        ) {
                          inLunchTime = true;
                        }
                      }
                      if (inLunchTime === false) {
                        availableDoctors.push(doctors[i]);
                      }
                    }
                  }
                }
              }
            }
            this.doctors = availableDoctors;
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    getClinicTypeFullText(item) {
      let clinicType = item.isVideoClinic ? "화상" : "음성";
      if (item.clinicType !== undefined && item.clinicType !== null) {
        if (item.clinicType === "ClinicType.normal") {
          clinicType = "대면";
        } else if (item.clinicType === "ClinicType.video") {
          clinicType = "화상통화";
        } else if (item.clinicType === "ClinicType.audio") {
          clinicType = "음성통화";
        }
      }
      return clinicType;
    },
    reset() {
      this.item = {};
      this.selectedAppUser = {};
      this.certificates = "";
      this.nonBenefitMedicines = "";
      this.etc = "";
      this.checkups = "";
      this.certificateEtc = [];
    },
    async loadNonBenefits() {
      try {
        this.certificates = "";
        this.nonBenefitMedicines = "";
        this.etc = "";
        this.certificateEtc = [];
        this.checkups = "";
        if (this.$utils.isEmpty(this.item.requestNonBenefits) === false) {
          for (let i = 0; i < this.item.requestNonBenefits.length; i++) {
            // 선택정보
            let selectionsPrice = 0;
            let selectionsOptions = "";
            if (
              this.$utils.isEmpty(
                this.item.requestNonBenefits[i].selectionsRequests
              ) === false
            ) {
              let selectionsRequests =
                this.item.requestNonBenefits[i].selectionsRequests;
              for (let j = 0; j < selectionsRequests.length; j++) {
                if (j > 0) {
                  selectionsOptions += ", ";
                }
                selectionsOptions += "선택정보" + (j + 1) + " : ";
                if (
                  this.$utils.isEmpty(selectionsRequests[j].options) === false
                ) {
                  for (
                    let k = 0;
                    k < selectionsRequests[j].options.length;
                    k++
                  ) {
                    let option = selectionsRequests[j].options[k];
                    selectionsPrice += option.price;
                    if (k > 0) {
                      selectionsOptions += ", ";
                    }
                    selectionsOptions +=
                      option.name +
                      " " +
                      this.$utils.separateThousands(option.price + "") +
                      "원";
                  }
                } else {
                  this.item.requestNonBenefits[i].selectionsRequests[
                    j
                  ].options = [];
                }
              }
            }

            // 추가선택정보
            if (
              this.$utils.isEmpty(
                this.item.requestNonBenefits[i].additionalOptions
              ) === false
            ) {
              let additionalOptions =
                this.item.requestNonBenefits[i].additionalOptions;
              for (let k = 0; k < additionalOptions.length; k++) {
                let option = additionalOptions[k];
                selectionsPrice += option.price;
                if (selectionsOptions.length > 0) {
                  selectionsOptions += ", ";
                }
                selectionsOptions += "추가선택정보" + (k + 1) + " : ";
                selectionsOptions +=
                  option.name +
                  " " +
                  this.$utils.separateThousands(option.price + "") +
                  "원";
              }
            }

            // 비급여항목
            if (
              this.item.requestNonBenefits[i].type ===
              "NonBenefitType.certificate"
            ) {
              if (this.certificates.length > 0) {
                this.certificates += ", ";
              }
              this.certificates +=
                this.item.requestNonBenefits[i].name +
                " " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + ""
                ) +
                "원";
              if (selectionsOptions.length > 0) {
                this.certificates += " (" + selectionsOptions + ")";
              }
              this.certificates +=
                " : 총 " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + selectionsPrice + ""
                ) +
                "원";
            }
            if (
              this.item.requestNonBenefits[i].type ===
              "NonBenefitType.nonBenefitMedicine"
            ) {
              if (this.nonBenefitMedicines.length > 0) {
                this.nonBenefitMedicines += ", ";
              }
              this.nonBenefitMedicines +=
                this.item.requestNonBenefits[i].name +
                " " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + ""
                ) +
                "원";
              if (selectionsOptions.length > 0) {
                this.nonBenefitMedicines += " (" + selectionsOptions + ")";
              }
              this.nonBenefitMedicines +=
                " : 총 " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + selectionsPrice + ""
                ) +
                "원";
            }
            if (this.item.requestNonBenefits[i].type === "NonBenefitType.etc") {
              if (this.etc.length > 0) {
                this.etc += ", ";
              }
              this.etc +=
                this.item.requestNonBenefits[i].name +
                " " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + ""
                ) +
                "원";
              if (selectionsOptions.length > 0) {
                this.etc += " (" + selectionsOptions + ")";
              }
              this.etc +=
                " : 총 " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + selectionsPrice + ""
                ) +
                "원";
            }
            if (
              this.item.requestNonBenefits[i].type ===
              "NonBenefitType.certificateEtc"
            ) {
              let etc = Object.assign(
                {
                  index: i,
                },
                this.item.requestNonBenefits[i]
              );
              this.certificateEtc.push(etc);
            }
            if (
              this.item.requestNonBenefits[i].type === "NonBenefitType.checkup"
            ) {
              if (this.checkups.length > 0) {
                this.checkups += ", ";
              }
              this.checkups +=
                this.item.requestNonBenefits[i].name +
                " " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + ""
                ) +
                "원";
              if (selectionsOptions.length > 0) {
                this.checkups += " (" + selectionsOptions + ")";
              }
              this.checkups +=
                " : 총 " +
                this.$utils.separateThousands(
                  this.item.requestNonBenefits[i].price + selectionsPrice + ""
                ) +
                "원";
            }
          }
        }
        if (this.certificates.length === 0) {
          this.certificates = "없음";
        }
        if (this.nonBenefitMedicines.length === 0) {
          this.nonBenefitMedicines = "없음";
        }
        if (this.etc.length === 0) {
          this.etc = "없음";
        }
        if (this.checkups.length === 0) {
          this.checkups = "없음";
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addNonBenefitItems() {
      this.workingStateDialog = true;
      let error = false;
      for (let type = 0; type < 4; type++) {
        let nonBenefits = [];
        if (type === 0) {
          nonBenefits = this.addNonBenefitCertificates;
        } else if (type === 1) {
          nonBenefits = this.addNonBenefitMedicines;
        } else if (type === 2) {
          nonBenefits = this.addNonBenefitEtc;
        } else if (type === 3) {
          nonBenefits = this.addNonBenefitCheckups;
        }
        for (let i = 0; i < nonBenefits.length; i++) {
          let nonBenefit = nonBenefits[i];
          let checked = this.nonBenefitCheckMap[nonBenefit.id];
          if (checked) {
            if (this.$utils.isEmpty(nonBenefit.selections) === false) {
              let selectionsRequests = [];
              for (let j = 0; j < nonBenefit.selections.length; j++) {
                let selection = nonBenefit.selections[j];
                console.log("selection", selection);

                let requiredCount = 0;
                if (this.$utils.isEmpty(selection.requiredCount) === false) {
                  requiredCount = selection.requiredCount;
                }
                if (this.$utils.isEmpty(selection.options) === false) {
                  let checkedCount = 0;
                  let optionIds = [];
                  let options = [];
                  for (let k = 0; k < selection.options.length; k++) {
                    if (selection.options[k].checked) {
                      optionIds.push(selection.options[k].id);
                      options.push(selection.options[k]);
                      checkedCount++;
                    }
                  }
                  if (checkedCount !== requiredCount) {
                    this.showWarningSnackbar(
                      nonBenefit.name +
                        "의 선택 옵션" +
                        (j + 1) +
                        "에서 반드시 " +
                        requiredCount +
                        "개를 선택해주세요."
                    );
                    error = true;
                    break;
                  }
                  selectionsRequests.push({
                    optionIds: optionIds,
                    options: options,
                  });
                }
                if (error) {
                  break;
                }
              }
              if (selectionsRequests.length > 0) {
                nonBenefit.selectionsRequests = selectionsRequests;
              }
            }
            if (error) {
              break;
            }
            if (this.$utils.isEmpty(this.item.requestNonBenefits)) {
              this.item.requestNonBenefits = [];
            }
            this.item.requestNonBenefits.push(nonBenefit);
          }
        }
      }
      if (error === false) {
        if (this.addNonBenefitCertificateUserReq.checked) {
          let model = this.$models.NonBenefitModel.create();
          model.createAt = this.$database.currentTimestamp();
          model.type = "NonBenefitType.certificateEtc";
          model.name =
            "기타(" + this.addNonBenefitCertificateUserReq.name + ")";
          if (this.$utils.isEmpty(this.item.requestNonBenefits)) {
            this.item.requestNonBenefits = [];
          }
          this.item.requestNonBenefits.push(model);
        }
      }
      if (error === false) {
        this.workingStateDialog = false;
        this.loadNonBenefits();
        this.addNonBenefitDialog = false;
        this.showInfoSnackbar("추가되었습니다.");
      } else {
        this.workingStateDialog = false;
      }
    },
    showAddNonBenefitDialog() {
      this.nonBenefitCheckMap = {};
      for (let type = 0; type < 4; type++) {
        let nonBenefits = [];
        if (type === 0) {
          nonBenefits = this.addNonBenefitCertificates;
        } else if (type === 1) {
          nonBenefits = this.addNonBenefitMedicines;
        } else if (type === 2) {
          nonBenefits = this.addNonBenefitEtc;
        } else if (type === 3) {
          nonBenefits = this.addNonBenefitCheckups;
        }
        for (let i = 0; i < nonBenefits.length; i++) {
          let nonBenefit = nonBenefits[i];
          if (this.$utils.isEmpty(nonBenefit.selections) === false) {
            for (let j = 0; j < nonBenefit.selections.length; j++) {
              let selection = nonBenefit.selections[j];
              if (this.$utils.isEmpty(selection.options) === false) {
                for (let k = 0; k < selection.options.length; k++) {
                  selection.options[k].checked = false;
                }
              }
            }
          }
        }
      }
      this.addNonBenefitCertificateUserReq.name = "";
      this.addNonBenefitCertificateUserReq.checked = false;
      this.addNonBenefitDialog = true;
    },
    getAge(item) {
      let result = "";
      if (this.$utils.isEmpty(item.birth) === false) {
        let birth = this.$database.timestampToDate(item.birth);
        let today = new Date();
        let age = today.getFullYear() - birth.getFullYear();
        let month = today.getMonth() - birth.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birth.getDate())) {
          age--;
        }
        result = age + ", ";
      }
      return result;
    },
    async init() {
      this.hospitalWorkerId = this.$auth.currentUser().uid;
      // test
      //this.hospitalWorkerId = 'hospital365';
      try {
        this.hospitalWorker = await this.$database.loadHospitalWorker(
          this.hospitalWorkerId
        );
        this.hospital = await this.$database.loadHospital(
          this.hospitalWorker.hospitalId
        );
        this.doctors = [];

        // let allUsersMap = {};
        // let allUsers = await this.$database.loadUsersAll();
        let hPatientList = await this.$database.loadUsersAllByHospitalId(
          this.hospital.id
        );
        let hPatientsMap = {};
        for (let i = 0; i < hPatientList.length; i++) {
          if (this.$utils.isEmpty(hPatientList[i].name) === false) {
            // allUsersMap[allUsers[i].id] = allUsers[i];
            hPatientsMap[hPatientList[i].id] = hPatientList[i];
            // if (allUsers[i].currentHospitalId === this.hospital.id) {
            //   hospitalPatientsMap[allUsers[i].id] = allUsers[i];
            // }
          }
        }
        let hospitalWorks = await this.$database.loadHospitalWorksAll(
          this.hospital.id
        );
        let noUserMap = {};
        for (let i = 0; i < hospitalWorks.length; i++) {
          if (
            this.$utils.isEmpty(hPatientsMap[hospitalWorks[i].patientId]) ===
              true &&
            this.$utils.isEmpty(noUserMap[hospitalWorks[i].patientId]) === true
          ) {
            // this.$utils.writeLog("* loadUser : " + hospitalWorks[i].patientId);
            let userInfo = await this.$database.loadUser(
              hospitalWorks[i].patientId
            );
            if (this.$utils.isEmpty(userInfo) === false) {
              hPatientsMap[hospitalWorks[i].patientId] = userInfo;
            } else {
              noUserMap[hospitalWorks[i].patientId] = true;
            }
          }
        }
        let keys = Object.keys(hPatientsMap);
        let appUsers = [];
        for (let i = 0; i < keys.length; i++) {
          appUsers.push(hPatientsMap[keys[i]]);
        }
        appUsers.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        this.appUsers = appUsers;

        // 비급여항목
        let nonBenefits = await this.$database.loadNonBenefits(
          this.hospital.id
        );
        for (let i = 0; i < nonBenefits.length; i++) {
          nonBenefits[i].createAtText = this.$moment(
            nonBenefits[i].createAt.toDate()
          ).format("YYYY/MM/DD HH:mm:ss");
          if (nonBenefits[i].type === "NonBenefitType.certificate") {
            nonBenefits[i].typeText = "제증명서";
            this.addNonBenefitCertificates.push(nonBenefits[i]);
          } else if (nonBenefits[i].type === "NonBenefitType.certificateEtc") {
            nonBenefits[i].typeText = "제증명서";
            this.addNonBenefitCertificateEtc.push(nonBenefits[i]);
          } else if (
            nonBenefits[i].type === "NonBenefitType.nonBenefitMedicine"
          ) {
            nonBenefits[i].typeText = "비급여처방/시술";
            this.addNonBenefitMedicines.push(nonBenefits[i]);
          } else if (nonBenefits[i].type === "NonBenefitType.etc") {
            nonBenefits[i].typeText = "제품/서비스/기타";
            this.addNonBenefitEtc.push(nonBenefits[i]);
          } else if (nonBenefits[i].type === "NonBenefitType.checkup") {
            nonBenefits[i].typeText = "건강검진/검사";
            nonBenefits[i].key = 0;
            this.addNonBenefitCheckups.push(nonBenefits[i]);
          }
          this.nonBenefitCheckMap[nonBenefits[i].id] = false;
        }
        this.addNonBenefitCertificateUserReq.name = "";
        this.addNonBenefitCertificateUserReq.checked = false;

        await this.loadNonBenefits();
      } catch (e) {
        console.log(e);
      }
    },
    isAvailableReservation(availableCount) {
      if (availableCount === "휴게시간" || availableCount === "0명") {
        return false;
      } else {
        return true;
      }
    },
    onClickReservationTime(reservationTime) {
      if (this.isAvailableReservation(reservationTime.availableCount)) {
        this.item.reservationTime = this.$moment(reservationTime.time).format(
          "HH시 mm분"
        );
        this.changeTimeDialog = false;
        this.loadAvailableDoctors();
      }
    },
    async saveReservation() {
      if (this.$utils.isEmpty(this.item.reservationDate)) {
        this.showWarningSnackbar("예약일자를 선택해주세요.");
      } else if (this.$utils.isEmpty(this.item.reservationTime)) {
        this.showWarningSnackbar("예약시간을 선택해주세요.");
      } else if (this.$utils.isEmpty(this.selectedAppUser.id)) {
        this.showWarningSnackbar("고객을 선택해주세요.");
      } else if (this.$utils.isEmpty(this.item.reservationDoctor)) {
        this.showWarningSnackbar("담당의사를 선택해주세요.");
      } else if (this.$utils.isEmpty(this.item.reservationClinicType)) {
        this.showWarningSnackbar("진료형태를 선택해주세요.");
      } else if (this.$utils.isEmpty(this.item.reservationPayType)) {
        this.showWarningSnackbar("결제방법을 선택해주세요.");
      } else {
        let timeText = this.item.reservationTime.replace("시 ", ":");
        timeText = timeText.replace("분", ":00");
        let reservationDateTime = new Date(
          this.item.reservationDate + "T" + timeText
        );
        let reservationTimestamp =
          this.$database.dateToTimestamp(reservationDateTime);
        let clinicType = "";
        if (this.item.reservationClinicType === "대면진료") {
          clinicType = "ClinicType.normal";
        } else if (this.item.reservationClinicType === "음성진료") {
          clinicType = "ClinicType.audio";
        } else if (this.item.reservationClinicType === "화상진료") {
          clinicType = "ClinicType.video";
        }
        let isAutoPay = true;
        if (this.item.reservationPayType === "직접결제") {
          isAutoPay = false;
        }
        try {
          if (this.$utils.isEmpty(this.item.symptomDescription)) {
            this.item.symptomDescription = "";
          }
          let billingKey = "";
          if (this.$utils.isEmpty(this.selectedAppUser) === false) {
            billingKey = this.selectedAppUser.myMainCardBillingKey;
          }
          if (this.$utils.isEmpty(this.item.requestNonBenefits)) {
            this.item.requestNonBenefits = [];
          }
          let newItem = Object.assign(this.$models.HospitalWorkModel.create(), {
            createAt: this.$database.currentTimestamp(),
            reservationDateTime: reservationTimestamp,
            patientId: this.selectedAppUser.id,
            patientFamilyId: "본인",
            patientName: this.selectedAppUser.name,
            patientAddress: this.selectedAppUser.address,
            patientAddressDetail: this.selectedAppUser.addressDetail,
            patientPhone: this.selectedAppUser.phone,
            patientResidentNumber: this.selectedAppUser.residentNumber,
            patientGender: this.selectedAppUser.gender,
            patientBirth: this.selectedAppUser.birth,
            medicationInUse: this.selectedAppUser.medicationInUse,
            allergy: this.selectedAppUser.allergy,
            diseases: this.selectedAppUser.diseases,
            isSecondTimeClinic: false,
            clinicType: clinicType,
            isVideoClinic: clinicType === "ClinicType.video",
            isAutoPay: isAutoPay,
            symptomDescription: this.item.symptomDescription,
            images: [],
            requestNonBenefits: this.item.requestNonBenefits,
            hospitalId: this.hospital.id,
            hospitalName: this.hospital.name,
            doctorId: this.item.reservationDoctor.id,
            doctorJob: this.item.reservationDoctor.job,
            doctorName: this.item.reservationDoctor.name,
            patientUserMemo: "",
            clinicState: "ClinicState.accepted",
            isUserReceivedPayMessage: false,
            billingKey: billingKey,
            patientRelationWithUser: "본인",
            familyCertificateUrl: null,
            payFail: false,
          });
          newItem.prescriptionFileUrl = null;
          newItem.nextClinicDate = null;
          newItem.nextScheduleAfter = null;
          newItem.paymentAmount = null;
          newItem.priceBenefit = null;
          newItem.priceDelivery = null;
          newItem.priceNonBenefit = null;
          newItem.pricePoint = null;
          newItem.settleAmount = null;
          newItem.waitingTimeMinutes = null;

          console.log("newItem", newItem);

          newItem.id = await this.$database.addHospitalWork(newItem);
          let reservationDateTime = newItem.reservationDateTime.toDate();
          let message =
            "[" + this.$moment(reservationDateTime).format("M월 D일 H시");
          if (reservationDateTime.getMinutes() > 0) {
            message += " " + reservationDateTime.getMinutes() + "분";
          }
          message += "]" + " 예약이 완료됐어요.";
          await this.$database.addNotification({
            createAt: this.$database.currentTimestamp(),
            notificationType: "NotificationType.reservation",
            title: newItem.hospitalName,
            message: message,
            payload: {
              hospitalWorkId: newItem.id,
            },
            receiverUid: newItem.patientId,
          });
          let reservationDateTimeText =
            this.$moment(reservationDateTime).format("YYYY/MM/DD HH:mm");
          let kakaoMessage =
            newItem.patientName +
            "님.\n" +
            newItem.hospitalName +
            " 예약이 완료됐어요 ^^\n\n" +
            "예약일시 : " +
            reservationDateTimeText +
            "\n" +
            "담당 : " +
            newItem.doctorName +
            " " +
            newItem.doctorJob +
            "\n" +
            "형태 : " +
            this.getClinicTypeFullText(newItem) +
            "\n" +
            "결제방법 : " +
            (newItem.isAutoPay === false ? "직접결제" : "자동결제") +
            "\n\n" +
            "예약 변경/취소를 원하실 경우 예약내역에서 가능해요.";
          console.log("kakaoMessage", kakaoMessage);
          //this.$database.addMembershipUsagePushCount(this.hospital);
          this.$functions.sendKakao({
            title: newItem.hospitalName,
            message: kakaoMessage,
            number: newItem.patientPhone,
            tplCode: "TS_3639",
            button: [
              {
                name: "예약내역 확인하기",
                linkType: "WL",
                linkTypeName: "웹링크",
                linkMo: "https://link.flydoctor.kr",
                linkPc: "https://link.flydoctor.kr",
              },
            ],
          });
          this.changeReservationDialog = false;
          this.showInfoSnackbar("저장되었습니다.");
          //await this.loadItem();
          this.reset();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async showChangeTimeDialog() {
      this.reservationTimes = [];

      let reservationTimes = [];
      let openTime = new Date(this.item.reservationDate + "T00:00:00");
      let closeTime = new Date(openTime);
      let lunchStartTime = null;
      let lunchEndTime = null;
      let interval = 30;
      let patientCountLimit = 5;

      // 1. 병원 설정 정보 가져오기
      if (openTime.getDay() === 0) {
        // sunday
        if (this.hospital.sundayEnable) {
          openTime = this.hospital.sundayOpenAt.toDate();
          closeTime = this.hospital.sundayCloseAt.toDate();
          if (this.$utils.isEmpty(this.hospital.sundayLunchStartAt) === false) {
            lunchStartTime = this.hospital.sundayLunchStartAt.toDate();
            lunchEndTime = this.hospital.sundayLunchEndAt.toDate();
          }
        }
      } else if (openTime.getDay() === 1) {
        // monday
        if (this.hospital.mondayEnable) {
          openTime = this.hospital.mondayOpenAt.toDate();
          closeTime = this.hospital.mondayCloseAt.toDate();
          if (this.$utils.isEmpty(this.hospital.mondayLunchStartAt) === false) {
            lunchStartTime = this.hospital.mondayLunchStartAt.toDate();
            lunchEndTime = this.hospital.mondayLunchEndAt.toDate();
          }
        }
      } else if (openTime.getDay() === 2) {
        // tuesday
        if (this.hospital.tuesdayEnable) {
          openTime = this.hospital.tuesdayOpenAt.toDate();
          closeTime = this.hospital.tuesdayCloseAt.toDate();
          if (
            this.$utils.isEmpty(this.hospital.tuesdayLunchStartAt) === false
          ) {
            lunchStartTime = this.hospital.tuesdayLunchStartAt.toDate();
            lunchEndTime = this.hospital.tuesdayLunchEndAt.toDate();
          }
        }
      } else if (openTime.getDay() === 3) {
        // wednesday
        if (this.hospital.wednesdayEnable) {
          openTime = this.hospital.wednesdayOpenAt.toDate();
          closeTime = this.hospital.wednesdayCloseAt.toDate();
          if (
            this.$utils.isEmpty(this.hospital.wednesdayLunchStartAt) === false
          ) {
            lunchStartTime = this.hospital.wednesdayLunchStartAt.toDate();
            lunchEndTime = this.hospital.wednesdayLunchEndAt.toDate();
          }
        }
      } else if (openTime.getDay() === 4) {
        // thursday
        if (this.hospital.thursdayEnable) {
          openTime = this.hospital.thursdayOpenAt.toDate();
          closeTime = this.hospital.thursdayCloseAt.toDate();
          if (
            this.$utils.isEmpty(this.hospital.thursdayLunchStartAt) === false
          ) {
            lunchStartTime = this.hospital.thursdayLunchStartAt.toDate();
            lunchEndTime = this.hospital.thursdayLunchEndAt.toDate();
          }
        }
      } else if (openTime.getDay() === 5) {
        // friday
        if (this.hospital.fridayEnable) {
          openTime = this.hospital.fridayOpenAt.toDate();
          closeTime = this.hospital.fridayCloseAt.toDate();
          if (this.$utils.isEmpty(this.hospital.fridayLunchStartAt) === false) {
            lunchStartTime = this.hospital.fridayLunchStartAt.toDate();
            lunchEndTime = this.hospital.fridayLunchEndAt.toDate();
          }
        }
      } else if (openTime.getDay() === 6) {
        // saturday
        if (this.hospital.saturdayEnable) {
          openTime = this.hospital.saturdayOpenAt.toDate();
          closeTime = this.hospital.saturdayCloseAt.toDate();
          if (
            this.$utils.isEmpty(this.hospital.saturdayLunchStartAt) === false
          ) {
            lunchStartTime = this.hospital.saturdayLunchStartAt.toDate();
            lunchEndTime = this.hospital.saturdayLunchEndAt.toDate();
          }
        }
      }
      if (
        this.$utils.isEmpty(this.hospital.reservationInvervalMinutes) === false
      ) {
        interval = this.hospital.reservationInvervalMinutes * 1;
      }
      if (
        this.$utils.isEmpty(this.hospital.reservationPatientNumber) === false
      ) {
        patientCountLimit = this.hospital.reservationPatientNumber;
      }
      openTime = new Date(
        this.item.reservationDate +
          "T" +
          this.$moment(openTime).format("HH:mm") +
          ":00"
      );
      closeTime = new Date(
        this.item.reservationDate +
          "T" +
          this.$moment(closeTime).format("HH:mm") +
          ":00"
      );
      lunchStartTime = new Date(
        this.item.reservationDate +
          "T" +
          this.$moment(lunchStartTime).format("HH:mm") +
          ":00"
      );
      lunchEndTime = new Date(
        this.item.reservationDate +
          "T" +
          this.$moment(lunchEndTime).format("HH:mm") +
          ":00"
      );

      //console.log('openTime', openTime);
      //console.log('closeTime', closeTime);
      // console.log('lunchStartTime', lunchStartTime);
      // console.log('lunchEndTime', lunchEndTime);
      // console.log('interval', interval);
      // console.log('patientCountLimit', patientCountLimit);
      this.patientCountLimit = patientCountLimit;

      // 2. 예약정보 불러오기
      let hospitalWorks = await this.$database.loadHospitalWorks(
        this.hospital.id,
        this.$database.dateToTimestamp(openTime),
        this.$database.dateToTimestamp(closeTime),
        "",
        [
          "ClinicState.waiting",
          "ClinicState.accepted",
          "ClinicState.done",
          "ClinicState.inProgress",
        ],
        null
      );

      //console.log('hospitalWorks', hospitalWorks);

      // 시간대별로 loop
      for (
        let dateTime = openTime;
        dateTime < closeTime;
        dateTime.setMinutes(dateTime.getMinutes() + interval)
      ) {
        let availableCount = "";
        let reservationPatients = "";
        // 휴게시간이 있을 경우
        //console.log('lunchStartTime', lunchStartTime);
        //console.log('lunchEndTime', lunchEndTime);
        //console.log('dateTime', dateTime);
        if (lunchStartTime !== null && lunchEndTime !== null) {
          if (dateTime >= lunchStartTime && dateTime < lunchEndTime) {
            availableCount = "휴게시간";
          }
        }
        if (availableCount !== "휴게시간") {
          let durationStart = new Date(dateTime);
          let durationEnd = new Date(dateTime);
          durationEnd.setMinutes(durationEnd.getMinutes() + interval);
          //console.log('durationStart', durationStart);
          //console.log('durationEnd', durationEnd);
          let patientCount = 0;
          for (let i = 0; i < hospitalWorks.length; i++) {
            let reservationTime = hospitalWorks[i].reservationDateTime.toDate();
            //console.log('reservationTime', reservationTime);
            if (
              reservationTime >= durationStart &&
              reservationTime < durationEnd
            ) {
              patientCount++;
              if (reservationPatients.length > 0) {
                reservationPatients += ", ";
              }
              reservationPatients += hospitalWorks[i].patientName;
              //console.log('patientCount', patientCount);
            }
          }
          availableCount = patientCountLimit - patientCount;
          if (availableCount < 0) {
            availableCount = 0;
          }
          availableCount = availableCount + "명";
          //console.log('availableCount', availableCount);
        }
        reservationTimes.push({
          time: new Date(dateTime),
          availableCount: availableCount,
          reservationPatients: reservationPatients,
        });
      }

      //console.log('reservationTimes', reservationTimes);
      this.reservationTimes = reservationTimes;
      this.changeTimeDialog = true;
    },
    showWarningSnackbar(message) {
      this.snackbarColor = this.$theme.danger;
      this.snackbarMessage = message;
      this.snackbar = true;
    },
    showInfoSnackbar(message) {
      this.snackbarColor = this.$theme.primary;
      this.snackbarMessage = message;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped></style>

<style scoped>
.cell-border-tl {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
  height: 50px;
}

.cell-border-r {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.cell-border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-label {
  font-size: 14px;
}

th {
  font-size: 14px;
}
</style>
